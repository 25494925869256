interface VersionData {
  dev: {
    version: number;
    lastDeploy: string;
  };
  prod: {
    version: number;
    lastDeploy: string;
  };
}

// Default version if file doesn't exist
const defaultVersion: VersionData = {
  dev: {
    version: 1,
    lastDeploy: new Date().toISOString()
  },
  prod: {
    version: 1,
    lastDeploy: new Date().toISOString()
  }
};

// Statically import version at build time 
// This works in both server and client environments
let versionData: VersionData;
try {
  // Using require for JSON import (works in Next.js)
  versionData = require('../version.json');
} catch {
  versionData = defaultVersion;
}

// Check if we're in development or production
const isDev = (): boolean => 
  process.env.NODE_ENV === 'development' || 
  process.env.ENV_FILE === '.env.dev' ||
  process.env.NEXT_PUBLIC_ENV === 'dev';

export const getCurrentVersion = (): number => {
  return isDev() ? versionData.dev.version : versionData.prod.version;
}; 