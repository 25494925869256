import React, { useState, useEffect } from 'react'
import { getCurrentVersion } from '@/utils/version';

// Expose environment globally for components to access
const env = process.env.NEXT_PUBLIC_ENV || 'prod';
if (typeof window !== 'undefined') {
  // Make env available globally
  window.__ENV__ = env;
  console.log('Current environment:', env);
}

function DeployedVersionLabel() {
  const [version] = useState(() => {
    const currentVersion = getCurrentVersion();
    console.log('Environment variables:', {
      NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
      NODE_ENV: process.env.NODE_ENV,
      ENV_FILE: process.env.ENV_FILE
    });
    console.log('Detected version:', currentVersion);
    return currentVersion;
  });
  
  const isDevEnv = env === 'dev';
  
  return (
    <div className={`fixed top-0 left-0 z-50 px-2 py-1 text-[9px] opacity-50 hover:opacity-100 transition-opacity ${isDevEnv ? 'bg-yellow-200 text-black' : 'text-black'}`}>
      v{version} {isDevEnv && '(DEV)'}
    </div>
  )
}

// Add type definition for window
declare global {
  interface Window {
    __ENV__: string;
  }
}

export default DeployedVersionLabel